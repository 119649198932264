var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AppList"},[_c('v-data-iterator',{attrs:{"items":_vm.appListItems,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":-1,"loading":_vm.loading,"no-results-text":_vm.$t('iterator.no_related_results'),"hide-default-footer":""},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-toolbar',{style:({ marginLeft: '-10px', minWidth: 'calc(100% + 20px)' }),attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-toolbar-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-format-list-bulleted")]),_vm._v("APP 列表 ")],1),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-2",style:(_vm.handlerStyle),attrs:{"dense":"","outlined":"","single-line":"","hide-details":"","placeholder":_vm.$t('iterator.label.search')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-tooltip',{staticClass:"ma-0",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-magnify")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("iterator.textfield.search")))])])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}}),_c('v-btn-toggle',{attrs:{"mandatory":"","dark":"","dense":""},model:{value:(_vm.sortDesc),callback:function ($$v) {_vm.sortDesc=$$v},expression:"sortDesc"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","color":"secondary","value":false}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-up")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("iterator.sort_ascending")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","color":"secondary","value":true}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-down")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("iterator.sort_descending")))])])],1)],1)]},proxy:true},{key:"default",fn:function(props){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((props.items),function(item,idx){return _c('v-col',{key:idx + item.name,attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"3"}},[_c('AppListItem',{staticClass:"mr-4 mb-4",attrs:{"appItem":item}})],1)}),1)],1)]}},{key:"loading",fn:function(){return [_c('v-row',{staticClass:"mt-6"},_vm._l((10),function(n){return _c('v-col',{key:n,attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"3"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-skeleton-loader',{attrs:{"type":"image","min-width":250,"elevation":"0"}})],1)],1)}),1)]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"mt-12"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"projects-no-data",attrs:{"src":"/image/src/no_data.svg","alt":""}},on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("iterator.no-records")))])])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }