<template>
  <v-card
    :height="250"
    :to="{name:'PageList', params:{companyRoute:companyRoute,appRoute:appItem.route}}"
    :key="appItem.route"
  >
    <v-img
      src="/img/no-image.png"
      :height="250"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.3)"
    >
      <v-card-title >{{ appItem.name + `( ${appItem.route} )`}}</v-card-title>
      <!-- <v-card-subtitle class="text-white card-subtitle" v-text="appItem"></v-card-subtitle> -->
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    appItem: {
      type: Object
    }
  },
  data() {
    return {};
  },
  computed: {
    companyRoute() {
      return this.$route.params.companyRoute;
    }
  }
};
</script>

<style>
</style>