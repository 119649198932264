<template>
  <div class="AppList">
    <v-data-iterator
      :items="appListItems"
      :search="search"
      :sort-by="sortBy"
      :sort-desc.sync="sortDesc"
      :items-per-page="-1"
      :loading="loading"
      :no-results-text="$t('iterator.no_related_results')"
      hide-default-footer
    >
      <template #header>
        <v-toolbar
          dense
          flat
          color="transparent"
          :style="{ marginLeft: '-10px', minWidth: 'calc(100% + 20px)' }"
        >
        <v-toolbar-title >
            <v-icon left>mdi-format-list-bulleted</v-icon>APP 列表
        </v-toolbar-title>
        <v-spacer></v-spacer>

          <!--Search-->
          <v-text-field
            v-model.trim="search"
            dense
            outlined
            single-line
            hide-details
            :placeholder="$t('iterator.label.search')"
            :style="handlerStyle"
            class="mr-2"
          >
            <template v-slot:prepend-inner class="ma-0">
              <v-tooltip bottom class="ma-0">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-magnify</v-icon>
                </template>
                <span>{{ $t("iterator.textfield.search") }}</span>
              </v-tooltip>
            </template>
          </v-text-field>

          <!--Group By-->
          <!-- <v-select
            dense
            v-model="groupBy"
            outlined
            single-line
            hide-details
            :items="keys"
            :label="$t('project.group_by')"
            :placeholder="$t('project.group_by')"
            :style="handlerStyle"
            class="mr-2"
          >
            <template v-slot:prepend-inner>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-view-agenda-outline</v-icon>
                </template>
                <span>{{ $t("project.group_by") }}</span>
              </v-tooltip>
            </template>
            <template v-slot:selection="{ item }">{{ $t(`project.sort.${item}`) }}</template>
            <template v-slot:item="{ item }">{{ $t(`project.sort.${item}`) }}</template>
          </v-select>-->

          <!--Condition Filter-->
          <!-- <v-select
            v-model="condition"
            :items="conditions"
            dense
            outlined
            single-line
            hide-details
            :label="$t('project.conditions_filter')"
            :placeholder="$t('project.conditions_filter')"
            :style="handlerStyle"
            class="mr-2"
            clearable
          >
            <template v-slot:prepend-inner>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-filter-outline</v-icon>
                </template>
                <span>{{ $t("project.conditions_filter") }}</span>
              </v-tooltip>
            </template>

            <template v-slot:item="{ item }">{{ $t("project.condition." + item) }}</template>
            <template v-slot:selection="{ item }">{{ $t("project.condition." + item) }}</template>
          </v-select>-->

          <v-btn-toggle v-model="sortDesc" mandatory dark dense>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" depressed color="secondary" :value="false">
                  <v-icon small>mdi-arrow-up</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("iterator.sort_ascending") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" depressed color="secondary" :value="true">
                  <v-icon small>mdi-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("iterator.sort_descending") }}</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-toolbar>
      </template>

      <template #default="props">
        <v-container fluid>
          <v-row no-gutters>
            <v-col
              v-for="(item, idx) in props.items"
              :key="idx + item.name"
              cols="12"
              sm="6"
              md="6"
              lg="4"
              xl="3"
            >
              <AppListItem :appItem="item" class='mr-4 mb-4'></AppListItem>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #loading>
        <v-row class="mt-6">
          <v-col v-for="n in 10" :key="n" cols="12" sm="6" md="6" lg="4" xl="3">
            <v-card outlined>
              <v-skeleton-loader type="image" :min-width="250" elevation="0"></v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template #no-data>
        <div class="mt-12">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <img class="projects-no-data" v-on="on" src="/image/src/no_data.svg" alt />
            </template>
            <span>{{ $t("iterator.no-records") }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import AppListItem from "@/components/AppListItem";

export default {
  name: "AppList",
  components: {
    AppListItem
  },
  data() {
    return {
      appListItems: [],
      search: "",
      sortBy:"name",
      sortDesc: true,
      loading: false
    };
  },
  computed: {
    companyRoute() {
      return this.$route.params.companyRoute;
    },
    handlerStyle() {
      return { maxWidth: "250px" };
    }
  },
  async mounted() {
    this.loading = true;
    try {
      this.appListItems = await this.$API.api.main.companyApp
        .getAppByCompanyRoute(this.companyRoute)
        .then(res => res.data);
    } catch (err) {
      console.error("Error:", err);
    } finally {
      this.loading = false;
    }
  }
};
</script>

<style>
</style>